import React from "react";
import { Text } from "../../components";

const PromotingBonusRules = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          Promoting Bonus will be issued in the form of USDT, and the total bonus is exactly 20% of the total profit of the ∑ Network project in the current country in the latest reward period (usually 1 week or 2 weeks).
          </li>
          <li>
          When the ∑ Network project sets up a local promotion team in the current country or region, the team CEO him/herself will receive 5% of the total profit, and other team members will share 15% of the total profit as Promoting Bonus.
          </li>
          <li>
          When the ∑ Network project does not have a local promotion team in the current country or region, the top 20 users who have obtained the most Referral SIGMA during the reward period are eligible to participate in the Promoting Bonus event.
          </li>
          <li>
          The USDT bonus amount received by each promoter is strictly proportional to the amount of Referral SIGMA(which is the Credited SIGMA mined with the help of your referrals, including the following Referral Mining Bonus, Referral Purchase Mining, and Referral Staking Mining) during the reward period.
          </li>
          <li>
          The USDT bonus received by each promoter will be credited to the Withdrawable USDT balance in the user's Sigma Wallet, which can then be withdrawn or sent to other users.
          </li>
          <li>
          This reward does not conflict with other reward events, so each qualified user can participate in each reward event at the same time.
          </li>
          <li>
          This reward will be officially launched when the total number of ∑ Network users reaches 100,000. All rewards since the launch of the project will be distributed in the first reward event.
          </li>
          <li>
          ∑ Network DAO will regularly propose adjustments to the rules based on project needs and implement them after approval by the Management Committee.
          </li>
        </ol>
      </Text>
    </div>


);
};

export default PromotingBonusRules;
