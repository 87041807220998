import React from "react";
import { Text } from "../../components";

const EcosystemValue = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        The Sigma ecosystem provides free services to all ∑ Network users, thus gathering 1 billion global users and huge potential value.
        <br/>
        The value of the Sigma Ecosystem lays the foundation for the value of the ∑ Network project, thus avoiding it from falling into the trap of "only technology, no business" like many other projects.
        <br/>
        The ∑ Network project uses mature Internet business models to transform these potential values into actual business benefits, and then share them fairly with all ∑ Network users, thereby attracting more users to join the ∑ Network project.
        <br />
        You can learn more details about this topic in the white paper.
      </Text>
    </div>
);
};

export default EcosystemValue;
