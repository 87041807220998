import React from "react";
import { Text } from "../../components";

const EcosystemIntroduction = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

          The Sigma Ecosystem is a business ecosystem built on the ∑ Network. It encompasses a variety of Internet services including e-commerce, digital content, social media, entertainment, etc.
          <br/>
          These services will provide free services to ∑ Network users, and in the process gather rich business value. This value is the basis of the value of the ∑ Network project.
          <br/>
          You can learn more details about this topic in the white paper.
      </Text>
    </div>


);
};

export default EcosystemIntroduction;
