import React from "react";
import { useParams } from "react-router-dom";
import ProjectIntroduction from "./FAQ/1. What is ∑ Network";
import ProjectMission from "./FAQ/2. What is ∑ Network_s mission";
import ProjectFeatures from "./FAQ/3. Why is ∑ Network unique";
import ParticipateRules from "./FAQ/4. How to participate in ∑ Network";
import InviteParticipateRules from "./FAQ/5. How to invite others to participate";
import EcosystemIntroduction from "./FAQ/6. What is Sigma Ecosystem";
import EcosystemValue from "./FAQ/7. What is Sigma Ecosystem_s value";
import SIGMAIntroduction from "./FAQ/8. What is SIGMA";
import SIGMABenefitRules from "./FAQ/9. What are the benefits from SIGMA";
import MineSIGMARules from "./FAQ/10. How to mine SIGMA coins";
import SIGMAPrice from "./FAQ/11. How about the price of SIGMA";
import SIGMACirculation from "./FAQ/12. How is SIGMA coin circulated";
import ProfitRules from "./FAQ/13. How to earn profits";
import WithdrawFeature from "./FAQ/14. How to withdraw";
import SIGMAStakingRules from "./FAQ/15. What is SIGMA Staking";
import ProjectTeam from "./FAQ/16. How is the ∑ Network team";
import ProjectFutureVison from "./FAQ/17. What is our vision for the future";
import HoldingDividendRules from "./RewardActivity/How to earn Holding Dividend";
import MingingRewardRules from "./RewardActivity/How to earn Mining Reward";
import PromotingBonusRules from "./RewardActivity/How to earn Promoting Bonus";
import StakingAllowanceRules from "./RewardActivity/How to earn Staking Allowance";
import NotFound from "./NotFound";
const SecondPage = () => {
  const { first, second} = useParams();
  let page: {};
  switch (first) {
    case "faq":
      switch (second) {
        case "projectIntroduction":
          page = <ProjectIntroduction />;
          break;
        case "projectMission":
          page = <ProjectMission />;
          break;
        case "projectFeatures":
          page = <ProjectFeatures />;
          break;
        case "participateRules":
          page = <ParticipateRules />;
          break;
        case "inviteParticipateRules":
          page = <InviteParticipateRules />;
          break;
        case "ecosystemIntroduction":
          page = <EcosystemIntroduction />;
          break;
        case "ecosystemValue":
          page = <EcosystemValue />;
          break;
        case "SIGMAIntroduction":
          page = <SIGMAIntroduction />;
          break;
        case "SIGMABenefitRules":
          page = <SIGMABenefitRules />;
          break;
        case "mineSIGMARules":
          page = <MineSIGMARules />;
          break;
        case "SIGMAPrice":
          page = <SIGMAPrice />;
          break;
        case "SIGMACirculation":
          page = <SIGMACirculation />;
          break;
        case "profitRules":
          page = <ProfitRules />;
          break;
        case "withdrawFeature":
          page = <WithdrawFeature />;
          break;
        case "SIGMAStakingRules":
          page = <SIGMAStakingRules />;
          break;
        case "projectTeam":
          page = <ProjectTeam />;
          break;
        case "projectFutureVison":
          page = <ProjectFutureVison />;
          break;
        default:
          page = <NotFound />;
          break;
      }
      break;
    case "rewardActivity":
      switch (second) {
        case "holdingDividendRules":
          page = <HoldingDividendRules />;
          break;
        case "mingingRewardRules":
          page = <MingingRewardRules />;
          break;
        case "promotingBonusRules":
          page = <PromotingBonusRules />;
          break;
        case "stakingAllowanceRules":
          page = <StakingAllowanceRules />;
          break;
        default:
          page = <NotFound />;
          break;
      }
      break;
    default:
      page = <NotFound />;
  }

  return (
    <div>
      {page}
    </div>
  );
};
export default SecondPage;
