import React from "react";
import { Text } from "../../components";

const HoldingDividendRules = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          SIGMA Holding Dividend will be issued in the form of USDT, and the total dividend amount is exactly 20% of the total profit of the ∑ Network project in the latest reward period (usually 1 month or 2 months).
          </li>
          <li>
          Only SIGMA holders who hold more than 5,000 Credited SIGMA coins (all Credited SIGMA, including the SIGMA received from other users) are eligible to participate in the SIGMA Holding Dividend event.
          </li>
          <li>
          The USDT dividend amount received by each SIGMA holder is strictly proportional to the number of credited SIGMA coins he/she holds (all Credited SIGMA, including the SIGMA received from other users).
          </li>
          <li>
          The USDT rewards received by each SIGMA holder will be credited to the Withdrawable USDT balance in the user's Sigma Wallet, which can then be withdrawn or sent to other users.
          </li>
          <li>
          This reward does not conflict with other reward events, so each qualified user can participate in each reward event at the same time.
          </li>
          <li>
          This reward will be officially launched when the total number of ∑ Network users reaches 200,000. All rewards since the launch of the project will be distributed in the first reward event.
          </li>
          <li>
          ∑ Network DAO will regularly propose adjustments to the rules based on project needs and implement them after approval by the Management Committee.
          </li>
        </ol>
      </Text>
    </div>

);
};

export default HoldingDividendRules;
