import React from "react";
import { Text } from "../../components";

const SIGMACirculation = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        ∑ Network provides users with easy-to-use, high-speed, and low-cost SIGMA circulation tools on its ∑ Network App.
        <br/>
        You can use the "Send" function in Sigma Wallet to send SIGMA coins to other users, or use the "Receive" function to obtain SIGMA coins from other users. In this process, you need to use Sigma Wallet Address to uniquely mark a user.
        <br/>
        In addition, these 2 functions can also be used to send or receive USDT in Sigma Wallet.
        <br />
        You can learn more details about this on the Wallet page.
      </Text>
    </div>


);
};

export default SIGMACirculation;
