import React from "react";
import { Text } from "../components";

const SendRules = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          With the Send function, you can transfer SIGMA or USDT from your Sigma Wallet to an Sigma Wallet belonging to another Sigma ID.
          </li>
          <li>
          The currencies you can send include Credited SIGMA, Spendable USDT, and Withdrawable USDT.
          </li>
          <li>
          You need to ensure that the amount of Credited SIGMA in your Sigma Wallet reaches 5,000 before you can send SIGMA to other Sigma Wallet addresses,  or that the total USDT balance reaches 5 USDT before you can send USDT.
          </li>
          <li>
          You can manually enter a target Sigma Wallet address or scan the QR code of the target address. Please make sure the destination address is a correct Sigma Wallet address to avoid transfer failure.
          </li>
          <li>
          During the transfer, a gas fee of 1%-3% SIGMA/USDT of your transfer amount will be charged. This amount will be dynamically adjusted based on the actual fees of the ∑ Network.
          </li>
          <li>
          The SIGMA or USDT you send will normally be deposited into your designated Sigma Wallet address within 0.01-0.05 seconds.
          </li>
          <li>
          ∑ Network DAO will regularly propose adjustments to the rules based on project needs and implement them after approval by the Management Committee.
          </li>
        </ol>
      </Text>
    </div>


);
};

export default SendRules;



