import React from "react";
import { Text } from "../components";

const InvitationRules = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[18px] text-gray-900 w-full"
            size="txtInterSemiBold48">How to invite?</Text>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          Send an invitation to your friends. Make sure the invitation includes a link to the ∑ Network App and your own Sigma ID (which is also your invitation code). 
          </li>
          <li>
          Also, you can also post the invitation on your social media account or in any group.
          </li>
          <li>
          When someone installs the ∑ Network App, and fills in your Sigma ID in the "Inviter's Sigma ID" blank when signing up, your invitation is complete!
          </li>
        </ol>
      </Text>
      <br />
      <Text className="text-[18px] text-gray-900 w-full mt-3"
            size="txtInterSemiBold48">What to benefit from inviting?</Text>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          Every time you successfully invite a new user, you will immediately receive 5,000 Crediting SIGMA coins.
          </li>
          <li>
          When any of your invitees gets Credited SIGMA through Daily Mining, Purchasing or SIGMA Staking, an equal amount of your Crediting SIGMA will be automatically converted into Credited SIGMA in your Sigma Wallet, until all of the 5,000 Crediting SIGMA are converted.
          </li>
          <li>
          Every user you invite, as well as the users invited by your invitees and so on, will become a member of your Sigma Team. When members of your Sigma Team make consumption or staking in the Sigma Store or in SIGMA Staking, you will receive additional SIGMA coins as rebates.
          </li>
          <li>
          The above rule 3 is permanently valid during the existence of the ∑ Network project.
          </li>
        </ol>
      </Text>
    </div>

);
};

export default InvitationRules;
