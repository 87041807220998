import React from "react";
import { Text } from "../components";

const DepositRules = () => {
  return (


    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          With the Deposit function, you can deposit USDT into your Sigma Wallet.
          </li>
          <li>
          Save the QR code above, or directly copy the deposit address.
          </li>
          <li>
          Open your personal wallet APP and transfer USDT to the above address. Please be sure to choose the ERC-20 network during the transfer.
          </li>
          <li>
          The USDT you deposit will be credited in your Spendable USDT balance within 15 minutes.
          </li>
          <li>
          The above deposit address is valid for 15 minutes. Please complete the transfer within the validity period. When it exceeds 15 minutes, please obtain a new deposit address.
          </li>
          <li>
          Sigma Wallet does not currently support refunds, so please deposit with an appropriate amount according to your personal needs.
          </li>
          <li>
          ∑ Network DAO will regularly propose adjustments to the rules based on project needs and implement them after approval by the Management Committee.
          </li>
        </ol>
      </Text>
    </div>


);
};

export default DepositRules;
