import React from "react";
import { Text } from "../../components";

const ProfitRules = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        ∑ Network project ensures that every user can benefit from it's rapid development in various ways, including:
        <br/>
        <ol>
          <li>
            Continuously mine new SIGMA coins to earn the Mining Reward in USDT. Please see "How to mine SIGMA coins?" for specific mining methods. You can learn more details about this on the Mining Reward page.
          </li>
          <li>
            Hold SIGMA coins to receive the Holding Dividend in USDT. You can learn more details about this on the SIGMA Holding Dividend page.
          </li>
          <li>
            Promote ∑ Network to new users to get Promoting Bonus in USDT. You can learn more details about this on the Promoting Bonus page.
          </li>
          <li>
            Participate in SIGMA Staking to receive Staking Allowance. You can learn more details about this on the Staking Allowance page.
          </li>
          <li>
            When you promote other users to participate in SIGMA Staking, you will receive a very considerable Staking Promotion Bonus (5%-20% of the staking amount) in USDT . You can learn more details about this on the SIGMA Staking page.
          </li>
          <li>
            Send the SIGMA coins you hold to other users through the functions in Sigma Wallet to earn profits.
          </li>
          <li>
            Trade SIGMA on the open market when it's listed.
          </li>
        </ol>
        <br />
        The various methods above do not conflict with each other. Each user can benefit from the rapid development of ∑ Network through one or more of them at the same time.
      </Text>
    </div>



);
};

export default ProfitRules;

