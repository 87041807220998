import React from "react";
import { Text } from "../../components";

const ParticipateRules = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        Joining the ∑ Network project is very easy. You only need to install the ∑ Network App (https://Sigma.me/applink) on your phone, register a unique Sigma ID, and then you can start using it.
        <br/>
        Also, don’t forget to join our community to communicate with other users and get the latest updates. https://t.me/SigmaCommunity
        <br/>
        In addition, if you are a cryptocurrency investor or if you want to participate in the development of ∑ Network in other more in-depth ways, you can contact us at any time. Sigma.network@gmail.com
      </Text>
    </div>

);
};

export default ParticipateRules;
