import React from "react";
import { Text } from "../../components";

const SIGMABenefitRules = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

For every ∑ Network user, there are many benefits from mining and holding SIGMA coins, including:
        <br/>

        <ol>
          <li>
          When you newly mine enough SIGMA coins, you will be eligible to receive a Mining Reward.
          </li>
          <li>
          When you hold enough SIGMA coins, you will be eligible to receive SIGMA Holding Dividend.
          </li>
          <li>
          When ∑ Network project opens the public trading of SIGMA coins, you can earn profits by trading the SIGMA coins you hold.
          </li>
          <li>
          When the ∑ Network project decides to repurchase a portion of SIGMA coins to adjust the open market price, you have the opportunity to earn profits from it.
          </li>
          <li>
          The number of SIGMA coins you hold will determine the level of your Sigma ID. When it reaches a certain level, you will have the opportunity to join the Management Committee and participate in proposal review and project decision-making.
          </li>
        </ol>

        <br />
        You can learn more details about this topic in the white paper.

      </Text>
    </div>

);
};

export default SIGMABenefitRules;
