import React from "react";
import { Text } from "../components";

const ReceiveRules = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          With the Receive function, you can receive SIGMA or USDT from other users.
          </li>
          <li>
          Save the QR code above, or directly copy the Wallet Address.
          </li>
          <li>
          Send the QR code or Wallet Address to other users, and they can send SIGMA or USDT to this address through the Send function in ∑ Network App.
          </li>
          <li>
          The SIGMA or USDT sent to you will be credited into your Sigma Wallet within 0.01-0.05 seconds.
          </li>
          <li>
          The above QR code and Wallet Address belongs only to your Sigma ID and is permanently valid.
          </li>
          <li>
          ∑ Network DAO will regularly propose adjustments to the rules based on project needs and implement them after approval by the Management Committee.
          </li>
        </ol>
      </Text>
    </div>

);
};

export default ReceiveRules;
