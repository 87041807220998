import React from "react";
import { Text } from "../components";

const PrivacyPolicy = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[18px] text-gray-900 w-full"
            size="txtInterSemiBold48">Privacy Policy
      </Text>
      <br />
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full" size="txtInterRegular14">
        ∑ Network ("we" or "us") is committed to protecting the privacy of its users.
        <br />
        This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our website, applications, products, and services, as well as your rights.
        <br />
        We encourage you to carefully read this Privacy Policy (referred to as "this Policy" below) to understand in detail how we collect and use information, so that you can better understand our services and make appropriate choices.
        <br />
        By accessing or using the ∑ Network services, you agree to the terms set forth in this Policy. Unless otherwise specified, the terms used in this Policy have the same meanings as those in the "User Agreement."
        <br />
        If you have any questions, please contact us at Sigma.network@gmail.com.
      </Text>
      <br />

      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full" size="txtInterRegular14">
        Here is the outline of the key points extracted from our Privacy Policy, allowing you to quickly grasp the core ideas and principles:
      </Text>
      <br />
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ul>
          <li>
            We will outline the types of personal information we collect and their corresponding purposes, so you can understand the categories, purposes, and collection methods of specific personal information collected for specific functions.
          </li>
          <li>
            When you use certain features, we may collect some sensitive information with your consent. For example, when you use the Sigma Store feature, we may collect your location information. Refusing to provide this information will only result in your inability to use the specific feature, but it will not affect your normal use of other ∑ Network features.
          </li>
          <li>
            Currently, ∑ Network does not actively share or transfer your personal information to other third parties. If there are situations where you share or transfer your personal information to third parties, or if you need us to share or transfer your personal information to other third parties, we will directly obtain your express consent or confirm that the third party has obtained your express consent. Additionally, we conduct risk assessments on external information-sharing practices.
          </li>
          <li>
            Currently, ∑ Network does not actively obtain your personal information from third parties. If future business development requires us to indirectly obtain your personal information from third parties, we will clarify the source, type, and scope of your personal information before obtaining it. If the personal information processing activities required by ∑ Network's business exceed the scope of consent you provided to third parties when providing personal information, we will obtain your express consent before processing your personal information. Additionally, we strictly adhere to relevant laws and regulations and require third parties to ensure the legality of the information they provide.
          </li>
          <li>
            You can access, correct, or delete your personal information, withdraw consent, deactivate your account, file complaints, report issues, and adjust privacy settings through the channels listed in this Policy.
          </li>
        </ul>
      </Text>
      <br />
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full" size="txtInterRegular14">
        Here is the complete Privacy Policy of ∑ Network:
      </Text>
      <br />


      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>Our Principles
            <br />
            ∑ Network strictly adheres to laws and regulations and follows the following privacy protection principles to provide you with a safer and more reliable service:
            <br />
            <ul>
              <li>
                We strive to prevent the disclosure, damage, and loss of your information through reasonable and effective information security technologies and management processes.
              </li>
              <li>
                We provide convenient information management options for you to make appropriate choices and manage your personal information.
              </li>
              <li>
                We strictly comply with laws and regulations to protect your communication secrets and provide you with secure communication services.
              </li>
              <li>
                To provide better services to you and other users, we only collect necessary information and do not collect additional unnecessary information.
              </li>
              <li>
                We strive to introduce our privacy policy to you in a concise and understandable manner so that you can clearly understand how we handle your information.
              </li>
              <li>
                At every stage of product or service development, we integrate legal, product, design, and other factors into our privacy protection principles, incorporating privacy protection into product design.
              </li>
            </ul>
          </li>
          <br />
          <li>Information We Collect
            <br />
            Based on the principles of legality, legitimacy, and necessity, we only collect information necessary to fulfill the functions of our products. The information you actively provide when using our services includes:
            <br />
            <ul>
              <li>
                Information Provided When Creating Your Account:
                <br />
                Sigma ID and location information required when registering an ∑ Network account.
              </li>
              <li>
                Information Uploaded When Using Services:
                <br />
                You may upload your avatar when completing your personal information on ∑ Network.
              </li>
              <li>
                Information Submitted During KYC Verification:
                <br />
                During the KYC process, we may require information such as nationality, city of residence, name, gender, age, identification ID, email, etc.
              </li>
              <li>
                Information Submitted During Participation in Our Activities:
                <br />
                Some of our activities may require you to provide specific personal sensitive information to achieve certain functions. If you choose not to provide this information, you may not be able to use certain functions of the service normally, but it will not affect your use of other functions of the service.
              </li>
            </ul>
            <br />
            If you voluntarily provide personal sensitive information, it indicates that you agree to our processing of your personal sensitive information for the purposes and in the manner described in this policy.
          </li>
          <br />


          <li>Information Collected When You Use Our Services
            <ol>
              <li>Log Information
                <br />
                  When you use our services, we may automatically collect relevant information and store it as service log information.
                <br />
                <ul>
                  <li>
                    Device Information: Such as device model, operating system version, unique device identifiers, battery, signal strength, etc.
                  </li>
                  <li>
                    Software Information: Such as software version numbers, browser types. To ensure the security of the operating environment or to provide services, we collect information about the mobile applications and other software you use.
                  </li>
                  <li>
                    IP Address
                  </li>
                  <li>
                    Service Log Information: Such as information you search for, view, service error messages, recommended URLs, etc., when using our services.
                  </li>
                  <li>
                    Communication Log Information: Such as your account, communication time, and duration when using our services.
                  </li>
                </ul>
              </li>

              <li>Location Information
                <ul>
                  <li>
                    When you use location-related services, we may record your device's location information to provide you with relevant services.
                  </li>
                  <li>
                    We may obtain your geographic location information through methods such as IP addresses, GPS, WLAN (e.g., WiFi), or base stations when you use the service.
                  </li>
                  <li>
                    Information provided by you or other users during the use of the service may include your geographic location information, such as the region information in your account information or geographic tagging information shared by you or other users in photos.
                  </li>
                  <li>
                    When you use a specific feature of ∑ Network, we will record your geographic location information with your consent to provide you with this service. This information is sensitive. Refusing to provide this information will only result in your inability to use the above-mentioned features, but it will not affect your normal use of other ∑ Network functions. Additionally, you can disable related features at any time and clear your geographic location information within this feature.
                  </li>
                </ul>
              </li>
              <li>
                Other Relevant Information
                <br />
                When you use features such as Sigma Store, Sigma Social, Sigma Finance, we collect your search records, operation records, visit times, comments, and interaction records to provide you with more accurate and personalized services and content.
              </li>
              <li>
                Information Shared by Other Users, Including Your Information
                <br />
                For example, photos published by other users or shared videos may contain your information.
              </li>
              <li>
                Information Obtained from Third-Party Partners
                <br />
                <ul>
                  <li>
                    Third-party services in ∑ Network are provided by external third-party entities. ∑ Network cannot obtain information generated when you use such third-party services. However, if you expressly agree to provide your geographic location information to third parties, they will obtain your terminal's geographic location information through the ∑ Network interface. This information is sensitive. Refusing to provide this information will only result in your inability to use the above-mentioned third-party services, but it will not affect your normal use of other ∑ Network services. To facilitate authorization management, please carefully read the user agreements or privacy policies of third-party partner services.
                  </li>
                  <li>
                    Additionally, according to relevant laws, regulations, and national standards, we may collect and use your relevant personal information without your authorization in the following circumstances:
                    <ol>
                      <li>
                        Directly related to national security, national defense security, and other major public interests.
                      </li>
                      <li>
                        Directly related to criminal investigation, prosecution, trial, and execution of judgments.
                      </li>
                      <li>
                        Necessary to protect your or other individuals' vital interests such as life, property, and reputation.
                      </li>
                      <li>
                        Personal information collected by you is voluntarily made public.
                      </li>
                      <li>
                        Personal information collected from lawful public disclosures, such as lawful news reports, government information disclosures, etc.
                      </li>
                      <li>
                        Necessary for signing and performing contracts as required by you.
                      </li>
                      <li>
                        Necessary for the safe and stable operation of the products or services provided, such as discovering and handling product or service faults.
                      </li>
                      <li>
                        Necessary for lawful news reporting.
                      </li>
                      <li>
                        Necessary for statistics or academic research for public interests. When providing academic research results or descriptions, personal information contained in the results will be identified.
                      </li>
                      <li>
                        Other circumstances stipulated by laws and regulations.
                      </li>
                    </ol>
                  </li>
                  <li>

                  </li>
                </ul>
              </li>
            </ol>
            <br />
            Please understand that the functions and services we provide are constantly being updated and developed. If a certain function or service does not fall within the above description and collects your information, we will explain the content, scope, and purpose of the information collection to you and obtain your consent through page prompts, interaction processes, website announcements, etc. If in the future, business development requires us to indirectly obtain your personal information from third parties, we will clearly indicate the source, type, and scope of your personal information before obtaining it. If the processing of personal information required by ∑ Network's business activities exceeds the scope of authorization consent when you provide personal information to third parties, we will obtain your express consent before processing your personal information. Additionally, we will strictly abide by relevant laws and regulations and require third parties to ensure the legality of the information they provide.
          </li>
          <br />
          <li>How We Use Collected Information
            <br />
            We strictly adhere to legal regulations and agreements with users, and we use collected information for the following purposes. If we use your information beyond the following purposes, we will explain it to you again and obtain your consent.
            <br />
            <ul>
              <li>
                Providing Services: We use your information to provide services to you.
                <br />
                Meeting Your Personalized Needs: For example, language settings, location settings, personalized help services.
              </li>
              <li>
                Product Development and Service Optimization: For example, when our system fails, we record and analyze the information generated by the system failure to optimize our services.
              </li>
              <li>
                Security: We use your information for purposes such as identity verification, security, anti-fraud monitoring, file backup, customer security services. For example, security software you download or install detects malicious programs or viruses, or identifies fraudulent information for you.
              </li>
              <li>
                Recommending Advertisements and Information: We use your information to evaluate advertisements and information that you may be interested in and to improve the effectiveness of our advertising and other promotional activities.
              </li>
              <li>
                Software Management: For example, software certification, software upgrades, etc.
              </li>
              <li>
                Inviting You to Participate in Surveys Related to Our Services.
              </li>
            </ul>
            <br />
            In order to provide you with a better experience, improve our services, or for other purposes you agree to, we may use the information collected through certain services for our other services, provided that we comply with relevant laws and regulations. For example, we may use the information you provide when using one of our services to show you personalized content or advertisements in another service, and for user research, analysis, and statistics. To ensure the security of the service and help us better understand the operation of the application, we may record relevant information, such as the frequency of your application usage, error information, overall usage, performance data, and the source of the application. We do not combine the information stored in our analytics software with the personal identity information you provide in the application.
          </li>
          <br />
          <li>
            <ol>
              <li>
                You can access, modify, or delete the registration information and other personal information you provided during your use of our services, or contact us according to the instructions provided. The scope and method of accessing, modifying, and deleting personal information will depend on the specific service you use. For example, if you wish to stop sharing your location information when using location-related services, you can stop sharing by turning off the mobile location function, software and hardware service providers, and communication service providers. We recommend that you carefully read the relevant guidelines.
              </li>
              <li>
                We only collect and use your information in accordance with this policy to achieve the functions of our products or services. If you find that we collect or use your personal information in violation of laws, regulations, or agreements between us, you can request us to delete it. If you find that the personal information we collect and store about you is incorrect, you can also request us to correct it. Please contact us through the contact information listed in this policy. When you access, modify, or delete relevant information, we may ask you to verify your identity to ensure the security of your account. Please understand that due to technical limitations, legal or regulatory requirements, we may not be able to fulfill all your requests, and we will respond to your requests within a reasonable time.
              </li>
            </ol>
          </li>
          <br />
          <li>Information We Share
            <br />
            We strictly limit information sharing in accordance with laws and regulations. For example, with your prior consent, we may share your personal information with third parties solely for external processing purposes. We may collaborate with third-party partners (such as third-party service providers, contractors, agents, advertising partners) or application developers (for example, communication service providers who send emails or push notifications on our behalf, and map service providers who provide location services for us) (they may not be under your jurisdiction) and require them to follow our instructions, privacy policies, and other relevant confidentiality and security measures to process the information for us and for the following purposes:
            <br />
            <ul>
              <li>
                Providing our services to you.
              </li>
              <li>
                Achieving the purposes described in the "How We Use the Information We Collect" section.
              </li>
              <li>
                Fulfilling our obligations and exercising our rights under the "User Agreement" or this policy.
              </li>
              <li>
                Understanding, maintaining, and improving our services.
              </li>
            </ul>
            <br />
              If we share your information with the above-mentioned third parties, we will use encryption, anonymization, and other means to protect your information security. As our business continues to develop, if transactions such as mergers, acquisitions, or asset transfers lead to the sharing of your personal information with third parties, we will inform you of the relevant situation through push notifications, announcements, etc., and continue to protect your personal information in accordance with laws, regulations, and standards not lower than those required by this policy or request the new manager to continue protecting your personal information. We will utilize the collected information for big data analysis. For example, we will analyze the collected information to form city heat maps or industry insight reports that do not contain any personal information. We may disclose information that has been statistically processed and does not contain identifiable content to partners to understand how users use our services or to inform the public about the overall usage trends of our services.
            <br />
            We may disclose your personal information for the following purposes:
            <br />
            <ul>
              <li>
                Compliance with applicable laws and regulations.
              </li>
              <li>
                Compliance with court judgments, rulings, or other legal proceedings.
              </li>
              <li>
                Compliance with requests from relevant government agencies or other legally authorized organizations.
              </li>
              <li>
                When we believe it is necessary to comply with laws and regulations.
              </li>
              <li>
                Enforcement of relevant service agreements or this policy, maintenance of social public interests, and protection of the personal safety, property, or other legitimate rights and interests of customers, us, other users, or employees are reasonable and necessary.
              </li>
            </ul>

          </li>

          <br />
          <li>External Provision
            <br />
            Currently, we do not proactively share or transfer your personal information to third parties. If there are situations where we need to share or transfer your personal information to third parties, we will directly obtain your express consent or confirm that the third party has obtained your express consent for such actions. We do not publicly disclose the personal information collected. If public disclosure is necessary, we will inform you of the purpose, types of information disclosed, and any sensitive information involved, and obtain your express consent. As our business continues to develop, we may engage in transactions such as mergers, acquisitions, or asset transfers. We will inform you of the relevant circumstances and continue to protect your personal information in accordance with laws, regulations, and standards not lower than those required by this policy or require the new controller to continue protecting your personal information.
            <br />
            Additionally, according to relevant laws, regulations, and national standards, we may share, transfer, or publicly disclose personal information without obtaining the consent of the data subject in advance under the following circumstances:
            <br />
            <ul>
              <li>
                Directly related to public safety, public health, and significant public interests.
              </li>
              <li>
                Directly related to national security and national defense security.
              </li>
              <li>
                Directly related to criminal investigation, prosecution, trial, and enforcement of judgments.
              </li>
              <li>
                Necessary for the protection of the vital interests of the data subject or other individuals, where it is difficult to obtain the data subject's consent.
              </li>
              <li>
                Personal information disclosed by the data subject to the public.
              </li>
              <li>
                Personal information collected from legally disclosed information sources, such as lawful news reports, government information disclosure channels.
              </li>
            </ul>
          </li>
          <br />
          <li>Information We May Send to You
            <br />
            <ol>
              <li>
                Message Push: When you use our services, we may send you emails, SMS, messages, or push notifications. You can choose to unsubscribe from these notifications on your device based on our prompts.
              </li>
              <li>
                Service-Related Notices: We may issue service-related notices to you when necessary (such as when services are suspended due to system maintenance). You may not be able to unsubscribe from these non-advertising service-related notices.
              </li>
            </ol>
          </li>
          <br />
          <li>Information Storage Location and Duration
            <br />
            We comply with legal requirements and technical performance requirements by storing the personal information collected from users in a node network distributed globally.
            <br />
            Generally, we retain your personal information for the shortest time necessary to achieve the purpose. However, we may change the storage time of personal information to comply with legal requirements in the following situations:
            <br />
            <ul>
              <li>
                Compliance with applicable laws and regulations.
              </li>
              <li>
                Compliance with court judgments, rulings, or other legal procedures.
              </li>
              <li>
                Compliance with requests from government agencies or legitimate authorized organizations.
              </li>
              <li>
                When we believe it is necessary to comply with laws and regulations.
              </li>
              <li>
                Necessary for the protection of the vital interests of the data subject or other individuals, where it is difficult to obtain the data subject's consent.
              </li>
              <li>
                When our products or services cease operation, we will notify you via push notifications, announcements, etc., and delete or anonymize your personal information within a reasonable period.
              </li>
            </ul>
          </li>
          <br />


          <li>Information Security
            <br />
            We provide appropriate security protection for your information to prevent loss, improper use, unauthorized access, or disclosure of information.
            <br />
            <ul>
              <li>
                We strictly comply with laws and regulations to protect user communication confidentiality.
              </li>
              <li>
                We will implement various security measures at a reasonable security level to ensure the security of information. For example, we use encryption technologies (such as TLS, SSL), anonymization, and other means to protect your personal information.
              </li>
              <li>
                We have established dedicated management systems, processes, and organizations to ensure information security. For example, we strictly limit the scope of information access, require compliance with confidentiality obligations, and conduct reviews.
              </li>
              <li>
                In the event of a security incident such as personal information leakage, we will initiate emergency plans to prevent the expansion of security incidents and notify you through push notifications, announcements, etc.
              </li>
            </ul>
          </li>
          <br />
          <li>
            Changes
            <br />
            We may revise this privacy policy from time to time. When changes occur, we will show you the revised guidelines in the form of push notifications and pop-ups when you log in and update the version. Please note that we will only collect, use, and store your personal information in accordance with the updated guidelines after you click the consent button in the pop-up window.
          </li>
          <br />
          <li>
            Protection of Minors
            <br />
            We attach great importance to the protection of personal information of minors. According to relevant laws and regulations, if you are under 18 years old, you cannot register or use ∑ Network services. Please understand.
          </li>
          <br />
          <li>
            Scope of Application
            <br />
            This policy applies to all of our services.
            <br />
            <ul>
              <li>
                Some services have specific privacy guidelines/statements that more precisely describe how we handle your information within the service.
              </li>
              <li>
                If there is any inconsistency between this policy and the privacy guidelines/statements of specific services, please refer to the specific privacy guidelines/statements.
              </li>
              <li>
                Please note that this policy does not apply to services provided by other companies or individuals.
              </li>
              <li>
                When you use such third-party services, you must comply with their privacy policies (rather than this policy), and you need to carefully read their content.
              </li>
            </ul>
          </li>
          <br />
          <li>
            Contact Us
            <br />
            If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at Sigma.network@gmail.com.
          </li>
          <br />
          <li>
            Effective Date
            <br />
            This Privacy Policy becomes effective from the date you start using the project.
            <br />
            Thank you for reading and understanding.
            <br />
            We wish you a pleasant experience!
          </li>

        </ol>
      </Text>

    </div>

);
};

export default PrivacyPolicy;


