import React from "react";
import { Text } from "../components";

const InvitationRules = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          SIGMA Staking is a way to support the development of ∑ Network by staking cryptocurrency in the early stages of the project, and to obtain long-term, sustainable and considerable benefits from the rapid growth of ∑ Network.
          </li>
          <li>
          Each SIGMA Staking participant (SIGMA Staker) needs to pledge a certain amount of cryptocurrency (currently USDT is supported, and more cryptocurrencies will be supported in the future) to ∑ Network to be eligible to receive exclusive benefits and privileges from ∑ Network.
          </li>
          <li>
          Each SIGMA Staking will bring the following benefits to its SIGMA Staker:
            <ol>
              <li>
              A certain amount of SIGMA coins every week. The specific SIGMA amount is directly proportional to the staking amount. You can find the detailed data about the staking amount and the number of SIGMA coins you will receive on the SIGMA Staking page.
              </li>
              <li>
              Higher amounts of Mining Reward and SIGMA Holding Dividend, because these two reward amounts are proportional to the number of SIGMA coins you newly mine or hold respectively.
              </li>
              <li>
              Exclusive and considerable Staking Allowance. You can find the detailed description of Staking Allowance in the SIGMA Staking page.
              </li>
              <li>
              Many other exclusive benefits and privileges. You can find more detailed descriptions in the SIGMA Staking page.
              </li>
            </ol>
          </li>
          <li>
          Each user can participate in SIGMA Staking multiple times, and the above benefits for each SIGMA Staking will be calculated separately based on the respective take-effect time.
          </li>
          <li>
          The staking period of SIGMA Stake starts when each pledge takes effect and ends when the Sigma mainnet is released. When the Sigma mainnet is released, SIGMA Stakers can choose to release all the pledged cryptocurrencies in order, or exchange all pledged cryptocurrencies for equivalent SIGMA coins at 10% of the SIGMA market price.
          </li>
          <li>
          When each SIGMA Staking takes effect for 6 months, its staker can choose to release the staking early. If the staker chooses to release the staking early, ∑ Network will deduct 50% of the staking amount as the early release fee. After that, the SIGMA Staker will no longer receive the Staking Mining SIGMA coins and Staking Allowance corresponding to this SIGMA Staking.
          </li>
          <li>
          In addition, every core user who recognizes the values and ideals of the ∑ Network project can earn Referral Staking Reward of up to 20% of the total staking amount in batches by promoting SIGMA Staking.
          </li>
          <li>
          Staking is a common benefit mechanism in blockchain projects. While obtaining staking benefits, all stakers also need to fully understand the detailed rules of staking to be aware of the possible risks. The unique feature of SIGMA Staking is that during the staking period, SIGMA Stakers will regularly receive benefits in the form of USDT or others as hedge for any potential risks.
          </li>
          <li>
          ∑ Network DAO will regularly propose adjustments to the rules based on project needs and implement them after approval by the Management Committee.
          </li>
        </ol>
      </Text>
    </div>


);
};

export default InvitationRules;





