import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFound from "pages/NotFound";
import FirstPage from "./pages/First";
import SecondPage from "./pages/Second";


const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route path="/:first" element={<FirstPage />} />
          <Route path="/:first/:second" element={<SecondPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
