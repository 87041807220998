import React from "react";
import { Text } from "../../components";

const StakingAllowanceRules = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          The Staking Allowance will be issued in the form of USDT, and the total amount of the allowance is exactly 20% of the total profit of the ∑ Network project in the reward period (usually 1 month or 2 months).
          </li>
          <li>
          All SIGMA Staking participants are eligible to receive staking allowances.
          </li>
          <li>
          The USDT staking allowance received by each SIGMA Staker will be strictly proportional to the amount and duration of the staking during the reward period. The more staking you put in, the more allowance you receive.
          </li>
          <li>
          The USDT staking allowance received by each SIGMA Staker will be credited to the Withdrawable USDT balance in the user's Sigma Wallet, which can then be withdrawn or sent to other users.
          </li>
          <li>
          This reward does not conflict with other reward events, so each qualified user can participate in each reward event at the same time.
          </li>
          <li>
          This reward will be officially launched when the total number of ∑ Network users reaches 100,000. All rewards since the launch of the project will be distributed in the first reward event.
          </li>
          <li>
          ∑ Network DAO will regularly propose adjustments to the rules based on project needs and implement them after approval by the Management Committee.
          </li>
        </ol>
      </Text>
    </div>



);
};

export default StakingAllowanceRules;
