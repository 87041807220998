import React from "react";
import { Text } from "../components";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

function createData(
  ra: number,
  t: number,
  a: number,
  n: number,
  ad: number
) {
  return { ra, t, a, n, ad };
}

const table1 = [
  createData(0, 120, 5, 5, 60),
  createData(1000, 120, 10, 6, 120),
  createData(2000, 90, 15, 7, 240),
  createData(5000, 90, 25, 8, 400),
  createData(10000, 60, 35, 9, 840),
  createData(20000, 60, 35, 10, 1200),
  createData(50000, 30, 75, 11, 3600),
  createData(100000, 30, 100, 12, 4800)
];


const MineRules = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[18px] text-gray-900 w-full"
            size="txtInterSemiBold48">The 2 ways to mine Crediting SIGMA coins:</Text>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
            <div className={"font-inter font-semibold inline"}>
              Sign-up Bonus
            </div>

            <br />
            When you successfully sign up as an ∑ Network member, you will receive 5,000 Crediting SIGMA. When you
            get Credited SIGMA through Daily Mining, Purchasing or SIGMA Staking, an equal amount of Crediting SIGMA will
            automatically be converted to Credited SIGMA in your Sigma Wallet, until all 5,000 Crediting SIGMA are
            converted.
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Referral Bonus
            </div>
              <br />
              When you invite a friend to successfully sign up as an ∑ Network member, you will also receive 5,000
              Crediting SIGMA. When he/she gets Credited SIGMA through Daily Mining, Purchasing or SIGMA Staking, an equal
              amount of your Crediting SIGMA will be automatically converted into Credited SIGMA in your Sigma Wallet,
              until all of the 5,000 Crediting SIGMA are converted.
          </li>
        </ol>
      </Text>
      <Text className="text-[18px] text-gray-900 w-full mt-3"
            size="txtInterSemiBold48">The 8 ways to mine Credited SIGMA coins:</Text>
      <Text className="text-[14px] text-gray-900 w-full leading-[170.00%] "
            size="txtInterRegular14">
        <ol>
          <li>
            <div className={"font-inter font-semibold inline"}>
              Daily Mining
            </div>
              <br />
              This is a way to mine SIGMA coins for free.
              <br />
              When the mining machine starts working, an SIGMA block will be mined every certain minutes (T). When you
              click each block to confirm it, you will receive a certain number (A) of SIGMA coins. When the un-confirmed
              blocks reach a certain number (N), the mining machine will stop working until you confirm them, and then
              the
              mining machine will start working again.
              <br />
              In this rule, each miner's T, A, and N values are different, depending on the total amount of Referral
              SIGMA
              (RA in short, which is the Credited SIGMA mined with the help of your referrals, including the following
              Referral Mining Bonus, Referral Purchase Mining, and Referral Staking Mining) you have mined. The more
              Referral SIGMA you have mined, the more Credited SIGMA you will mine for free.
              <br />
              <br />
              Please see the table for details:
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 450 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align={"center"}>RA<br />
                        (more than...)</StyledTableCell>
                      <StyledTableCell align="center">T<br />
                        (in minutes)</StyledTableCell>
                      <StyledTableCell align="center">A</StyledTableCell>
                      <StyledTableCell align="center">N</StyledTableCell>
                      <StyledTableCell align="center">Max<br />SIGMA/Day</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table1.map((row) => (
                      <StyledTableRow key={row.ra}>
                        <StyledTableCell align="center" component="th" scope="row">
                          {row.ra}
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.t}</StyledTableCell>
                        <StyledTableCell align="center">{row.a}</StyledTableCell>
                        <StyledTableCell align="center">{row.n}</StyledTableCell>
                        <StyledTableCell align="center">{row.ad}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Purchase Mining
            </div>
              <br />
              This is a way for you to get additional SIGMA coins while shopping in Sigma Store.
              <br />
              There are many products you can buy in Sigma Store, which come from ∑ Network suppliers or
              third-party
              platforms that cooperate with ∑ Network.
              <br />
              When you shop in Sigma Store, you can get a certain amount of Credited SIGMA coins as a reward for free
              while getting the product itself. You can see the amount of SIGMA rewarded for each product in Sigma Store
              page.
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Staking Mining
            </div>
              <br />
              This is a way to regularly (by week) receive large amounts of SIGMA coins in return by participating in
              SIGMA
              staking.
              <br />
              You can view the instructions, privileges and specific rules for participating in SIGMA staking on the SIGMA
              Staking page.
              <br />
              Participating in SIGMA staking is a way to help ∑ Network grow and gain long-term benefits from the
              growth of it.
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Mining Bonus
            </div>
              <br />
              This is a mining method that automatically converts Crediting SIGMA in your wallet to Credited SIGMA for
              free.
              In this way, you can mine up to 5,000 SIGMA coins.
              <br />
              Please see Sign-up Bonus for specific rules.
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Event Earning
            </div>
              <br />
              When you participate in various reward events of ∑ Network (including Mining Reward, SIGMA Holding
              Dividend, Promoting Bonus, Staking Allowance), but do not receive USDT rewards, you will receive a random
              amount of SIGMA coins as compensation.
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Referral Mining Bonus
            </div>
              <br />
              This is a mining method that automatically converts Crediting SIGMA in your wallet to Credited SIGMA for
              free
              through all your referrals’ mining. In this way, you can mine up to 5,000 SIGMA coins from each referral.
              <br />
              Please see Referral Bonus for specific rules.
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Referral Purchase Mining
            </div>
              <br />
              When your referrals get SIGMA coins through Purchasing Mining, you, as the inviter, will also receive a
              certain proportion of SIGMA coin rewards.
              <br />
              Please note: The “referrals” mentioned here include not only new users you directly invite to sign up for
              ∑ Network, but also new users they continue to invite, as well as new users invited by new users, and
              so on. The closer you are to the initial purchasing user, the higher proportions of SIGMA coin rewards you
              will get.
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Referral Staking Mining
            </div>
              <br />
              When your referrals get SIGMA coins through Staking Mining every week, you, as the inviter, will also
              receive
              a certain proportion of SIGMA coin rewards.
              <br />
              Please note: The “referrals” mentioned here include not only new users you directly invite to sign up for
              ∑ Network, but also new users they continue to invite, as well as new users invited by new users, and
              so on. The closer you are to the initial staking user, the higher proportions of SIGMA coin rewards you
              will
              get.
          </li>
        </ol>
      </Text>
    </div>
);
};

export default MineRules;
